import { useAuth0 } from '@auth0/auth0-react';
import Login from '../auth/Login';


const UserData = () => {

    const { isLoading , isAuthenticated , loginWithRedirect } = useAuth0 ();

    if (isLoading)
        return <div>loading ...... </div>

    if ( !isAuthenticated ) {
        loginWithRedirect();
    }
    
    /* Logica a implementar , si el usuario completo los datos, entonces muestra el dashboard, sino captura de datos. */
    return <>
        <div> Aqui va el formulario de obtencion de datos ... </div>    
    </>
}

export default UserData;