import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './components/Landing';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Auth0Provider } from '@auth0/auth0-react';
import UserData from './components/user_data/UserData';

function App() {
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY?.trim();

/* Add to .env variables */
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  return (
    <Auth0Provider domain={domain} clientId={clientId} 
                   authorizationParams={{redirect_uri: window.location.origin}}
                   useRefreshTokens
                   cacheLocation='localstorage'>

        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/user-data" element={ <UserData/> } />
            </Routes>
          </BrowserRouter>
        </GoogleReCaptchaProvider>
    </Auth0Provider>
  );
}

export default App;
