import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const Profile = () => {

    const { user , logout } = useAuth0()
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    return <>
     <Box border={0}>
        <Box>
            <Typography fontFamily={'sans-serif'} fontWeight={'bold'}>Hola, { user.name }  </Typography>
        </Box>
     </Box>
     <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
         <Tooltip title="Account settings">
           <IconButton
             onClick={handleClick}
             size="small"
             sx={{ ml: 2 }}
             aria-controls={open ? 'account-menu' : undefined}
             aria-haspopup="true"
             aria-expanded={open ? 'true' : undefined}>
             <Avatar sx={{ width: 60, height: 60 }}>
                <img src={user.picture}/>
             </Avatar>
           </IconButton>
         </Tooltip>
      </Box>
      <Menu anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
               paper: {
                   elevation: 0,
                   sx: {
                     overflow: 'visible',
                     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                     mt: 1.5,
                     '& .MuiAvatar-root': {
                       width: 32,
                       height: 32,
                       ml: -0.5,
                       mr: 1,
                     },
                     '&::before': {
                       content: '""',
                       display: 'block',
                       position: 'absolute',
                       top: 0,
                       right: 14,
                       width: 10,
                       height: 10,
                       bgcolor: 'background.paper',
                       transform: 'translateY(-50%) rotate(45deg)',
                       zIndex: 0,
                     },
                   },
                 },
               }}
               transformOrigin={{ horizontal: 'right', vertical: 'top' }} 
               anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={ ()=> alert () }>
              <Avatar /> Mi Cuenta
            </MenuItem>
            <MenuItem onClick={ () => logout ({logoutParams: {returnTo: window.location.origin}}
            )}>
              <Avatar /> Salir
            </MenuItem>
        </Menu>
    </>
}

export default Profile;