
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { Person } from '@mui/icons-material';

const theme = createTheme();
theme.typography.h6 = {
  fontSize: '1.2rem',
  '@media (min-width:410px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.8rem',
  },
}


const Login = () => {

    const { loginWithRedirect } = useAuth0 ();

    return <>
     {
       <Button onClick={ () => loginWithRedirect () }  >
        <Box display={'inline'} >
          <Person  sx={{ fontSize : 40 , display : { xs : 'inline' , sm : 'none' }}} />
           <Typography color='black' fontWeight={'bold'} variant='h6' sx={{ display : { sm : 'inline' , xs : 'none' } }} >
              Iniciar Sesión 
           </Typography> 
        </Box>
       </Button>
     }
    </>
}


export default Login;